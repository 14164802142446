import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { ValidateAddress } from './validate_address'
import Header from './components/header'
import {
    HomepageTopSection,
    HomepageTopSectionMain,
    SectionContent,
    SectionImg,
    SectionRow
} from './components/styles/Homepage.styled'

export const Homepage = () => {
    const { t } = useTranslation('homepage')

    const images = [
        "https://dorzjv601fuva.cloudfront.net/tx/194.png",
        "https://dorzjv601fuva.cloudfront.net/tx/183.png",
        "https://dorzjv601fuva.cloudfront.net/tx/210.png",
        "https://dorzjv601fuva.cloudfront.net/tx/239.png",
        "https://dorzjv601fuva.cloudfront.net/tx/186.png",
        "https://dorzjv601fuva.cloudfront.net/tx/196.png",
        "https://dorzjv601fuva.cloudfront.net/tx/258.png",
        "https://dorzjv601fuva.cloudfront.net/tx/269.png",
        "https://dorzjv601fuva.cloudfront.net/tx/159.png",
        "https://dorzjv601fuva.cloudfront.net/tx/176.png",
        "https://dorzjv601fuva.cloudfront.net/tx/206.png",
        "https://dorzjv601fuva.cloudfront.net/tx/163.png",
        "https://dorzjv601fuva.cloudfront.net/tx/167.png",
        "https://dorzjv601fuva.cloudfront.net/tx/166.png",
    ];
    return (
        <>
            <Header />
            <Container>
                <HomepageTopSection>
                    <HomepageTopSectionMain>
                        <h1>{t('Save on your Electricity Bill')}</h1>
                        <p>
                            {t(
                                'Consolidated Texas Energy Marketplace to find the best electricity plan for your home'
                            )}
                        </p>
                        <ValidateAddress />
                    </HomepageTopSectionMain>
                </HomepageTopSection>
            </Container>
            <div style={{ borderTop: '1px solid #eee' }}>
                <Container>
                    <SectionRow reverse>
                        <SectionContent>
                            <h2>{t('3,000+ Plans Leaving You Overwhelmed?')}</h2>
                            <p>
                                {t(
                                    'Let Us Handle It. With eIQshopping’s cutting-edge AI-driven search engine, we scan and compare thousands of plans daily across the Texas marketplace. Simply enter your address to quickly find the most personalized plan for you, at no cost.'
                                )}
                            </p>
                            <p>{t('No hidden fees, variable rates, or surprises. Enjoy transparent and reliable energy plans for your home.')}</p>
                        </SectionContent>
                        <SectionImg>
                            <img src="/images/product-feature-shop.png" alt="" />
                        </SectionImg>
                    </SectionRow>
                </Container>
            </div>
            <Container>
            <h2 style={{textAlign:"center",fontWeight: 700}}>{t('We search all these energy suppliers and more')}</h2>
            
                <Row style={{margin:'20px 0'}} className="justify-content-center">
                    {images.map((src, index) => (
                        <Col
                            key={index}
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <div
                                style={{
                                    filter: 'grayscale(100%)',
                                    opacity: 0.5,
                                    transition: 'all 0.5s',
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={(e) => {
                                    (e.currentTarget as HTMLElement).style.opacity = '1';
                                    (e.currentTarget as HTMLElement).style.filter = 'grayscale(0)';
                                }}
                                onMouseLeave={(e) => {
                                    (e.currentTarget as HTMLElement).style.opacity = '0.5';
                                    (e.currentTarget as HTMLElement).style.filter = 'grayscale(100%)';
                                }}
                            >
                                <Image
                                    src={src}
                                    fluid
                                    alt="Save on Your Electricity Bill with Personalized.energy"
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )
}
