import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
    StyledFooter,
    Copyright,
    FooterNavRow,
    FooterNavCol,
    StyledFooterInner,
    SocialLinks,
    StyledFooterBottom
} from './styles/Footer.styled'
import moment from 'moment'

interface Item {
    title: string
    links: NavLink[]
}

interface NavLink {
    name: string
    url: string
    cnOnly?: boolean
}

const Footer = () => {
    const { t } = useTranslation()

    const nav: Item[] = [
        // {
        //     title: 'About Us',
        //     links: [
        //         { name: t('About Us'), url: 'https://www.eiqhome.com/utilities/about-us', },
        //         { name: t('Contact Us'), url: 'https://www.eiqhome.com/utilities/contact-us' },
        //         { name: 'Our Services', url: 'https://www.eiqhome.com/utilities/our-services' },
        //         { name: 'Press Release', url: 'https://www.eiqhome.com/utilities/category/press-release' }
        //     ],
        // },
        {
            title: 'Information',
            links: [
                { name: t('Terms of Service'), url: '/terms-of-service' },
                { name: t('Privacy Policy'), url: '/privacy_policy' },
                { name: t('FAQs'), url: '/faqs' },
            ],
        }
    ]

    return (
        <StyledFooter>
            <Container>
                <StyledFooterInner>
                    <FooterNavRow>
                        {nav.map((val, idx) => (
                            <FooterNavCol key={idx}>
                                <h6>{val.title}</h6>
                                {val.links.map((item, itemIdx) => {
                                    return (
                                        <div key={itemIdx}>
                                            <a href={item.url} rel="noreferrer">
                                                {item.name}
                                            </a>
                                        </div>
                                    )
                                })}
                            </FooterNavCol>
                        ))}
                    </FooterNavRow>
                    <div>
                        {/* <SocialLinks>
                            <a href="https://twitter.com/EiQhome" target="_blank" rel="noreferrer"><img src="/images/ft-social-link-tw.png" width="36" height="36" alt="" /></a>
                            <a href="https://fb.me/EIQhome" target="_blank" rel="noreferrer"><img src="/images/ft-social-link-fb.png" width="36" height="36" alt="" /></a>
                            <a href="https://www.linkedin.com/company/eiqhome" target="_blank" rel="noreferrer"><img src="/images/ft-social-link-in.png" width="36" height="36" alt="" /></a>
                            <a href="https://www.youtube.com/channel/UClBzvdtbP9BQ3B2eRrC7y6g" target="_blank" rel="noreferrer"><img src="/images/ft-social-link-youtube.png" width="36" height="36" alt="" /></a>
                        </SocialLinks> */}
                        <Copyright>
                            Copyright {moment().format('YYYY')} EIQhome. All Rights Reserved.<br />
                            PUCT Certificate #BR191039<br />
                            <a
                                href="https://www.eiqhome.com/utilities/llc-disclosure-statement"
                                target="_blank"
                                rel="noreferrer">
                                PUCT Required Disclosure
                            </a>
                        </Copyright>
                    </div>
                </StyledFooterInner>
                <StyledFooterBottom>
                    <a href="https://www.eiqdigital.com/" target="_blank"><img src="/images/powered_by_eiqdigital.png" width="220" alt="" /></a>
                </StyledFooterBottom>
            </Container>
        </StyledFooter>
    )
}

export default Footer
