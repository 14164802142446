// import { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useTranslation } from "react-i18next";
// import { IS_DEV } from '../config';
import { Container } from 'react-bootstrap';
import { css } from '@emotion/css';

const Header = () => {
    // const { t, i18n } = useTranslation();
    // const [ showNav, setShowNav ] = useState(false);

    // const path = IS_DEV ? '/dev' : '';

    return (
        <div className={css`
            border-bottom: 1px solid #ddd;
        `}>
            <Container>
                <div className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 80px;
                    a {
                        text-decoration: none;
                    }
                    span {
                        margin-left: 4px;
                        font-size: 12px;
                        color: #999;
                    }
                `}>
                    <a href="/">
                        <img src="/images/logo.png" height="50" alt="" />
                    </a>
                    <div className="d-none align-items-center justify-content-center d-md-flex d-lg-flex d-xl-flex">
                                <a href='/about_us' className="nav">About Us</a>
                                <a href='/contact-us' className="nav">Contact Us</a>
                                <a href='/faqs' className="nav">FAQs</a>
                    </div>
                    {/* <div className="d-none align-items-center justify-content-center d-md-flex d-lg-flex d-xl-flex">
                        <a href={i18n.language === 'en' ? `${path}/shopping_tips/`
                            : `${path}/shopping_tips_cn/`} className="nav">{t('Shopping Tips')}</a>
                    </div> */}
                    {/* <div className="d-block d-md-none">
                        <button className={css`
                            border: 0 none;
                            background: transparent;
                            cursor: pointer;
                        `} onClick={() => {setShowNav(!showNav);}}>
                            <FontAwesomeIcon icon="bars" style={{fontSize:20,color:'#666'}} />
                        </button>
                    </div> */}
                </div>
            </Container>
            {
                // showNav &&
                // <div className={css`
                //     position: fixed;
                //     top: 0;
                //     right: 0;
                //     bottom: 0;
                //     left: 0;
                //     background: #fff;
                //     z-index: 999;
                // `}>
                //     <div className={css`
                //         display: flex;
                //         flex-direction: column;
                //         align-items: center;
                //         justify-content: center;
                //         height: 100%;
                //     `}>
                //         <a href={i18n.language === 'en' ? `${path}/shopping_tips/`
                //             : `${path}/shopping_tips_cn/`} className="nav" style={{marginTop:20,marginBottom:20}}>{t('Shopping Tips')}</a>
                //     </div>
                //     <button className={css`
                //         display: inline-block;
                //         position: absolute;
                //         top: 16px;
                //         right: 10px;
                //         border: 0 none;
                //         background: transparent;
                //         cursor: pointer;
                //         z-index: 100;
                //     `} onClick={() => setShowNav(false)}>
                //         <FontAwesomeIcon icon="times-circle" style={{color:'#aaa',fontSize:36}} />
                //     </button>
                // </div>
            }
        </div>
    )
}

export default Header