import React, { useState } from 'react';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import { Modal, Spinner, Button as BootstrapButton, ListGroup } from 'react-bootstrap';
import type { BillReaderModalProps } from '../types';
import { Button } from './styles/Button.styled';
import Dropzone from 'react-dropzone';
import { billReader, fetchAddress, fetchUtility } from '../api/api';
import { toast } from 'react-toastify';

export const BillReaderModal: React.FC<BillReaderModalProps> = ({
    show,
    handleClose
}) => {
    const navigate = useNavigate();
    const [ files, setFiles ] = useState<any>(undefined);
    const [ loading, setLoading ] = useState(false);
    const [ address, setAddress ] = useState<Array<any>>([]);
    const [ view, setView ] = useState('upload');
    const [ selectedAddresKey, setSelectedAddressKey ] = useState<number|undefined>(undefined);

    const handleUpload = () => {
        if (!files || loading) return;

        setLoading(true);
        billReader(files).then(res => {
            setLoading(false);
            let addrs = undefined;

            if (res.addrs && res.addrs.length > 0) {
                addrs = res.addrs;
            }
            else {
                if (res.service_address) {
                    addrs = res.service_address;
                }
            }
            
            if (addrs) {
                let addessArr = [],
                    err_count = 0;

                for (let i in addrs) {
                    if (addrs[i].road) {
                        addessArr.push(addrs[i]);
                    }
                    else {
                        err_count += 1;
                    }
                }
                if (err_count === addrs.length) {
                    toast.error('Please try to improve the image resolution');
                    return;
                }
                setAddress(addessArr);
                setSelectedAddressKey(0);
                setView('confirm ');
            }
            else {
                toast.error('An error occurred, please try again later');
            }
        });
    }

    const handleSearch = () => {
        if (selectedAddresKey === undefined) {
            toast.error('Please select a address from the list');
            return;
        }
        setLoading(true);
        fetchAddress(mergeAddress(address[selectedAddresKey])).then(res => {
            if (res.length === 0) {
                toast.error('An error occurred, please try again later');
                setLoading(false);
                return;
            }
            const address = res[0]
            const { street, esiid, zipcode, city, state } = address
            fetchUtility(zipcode).then(utRes => {
                if (utRes.length === 0) {
                    toast.error('An error occurred, please try again later');
                    setLoading(false);
                    return;
                }
                navigate(`/plans?address=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utcode=${utRes[0] ? utRes[0].utility_code : ''}`);
            })
            // navigate(`/plans?address=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}`);
        })
    }

    const uploadView = () => {
        return (
            <div>
                <Dropzone onDrop={acceptedFiles => {
                    setFiles(acceptedFiles[0]);
                }} disabled={loading}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className={ files ? "drop-area active" : "drop-area" }>
                            <input {...getInputProps()} />
                            {
                                files ?
                                <div style={{fontSize:24,fontWeight:700}}>{files.name}</div> :
                                <div style={{fontSize:24,fontWeight:700}}>Upload Bill</div>
                            }
                            <div className="mt-2" style={{color:'#666'}}>(Supports PDF, JPG, PNG)</div>
                        </div>
                    )}
                </Dropzone>
                <Button onClick={handleUpload}>
                    Upload
                    { loading && <Spinner animation="border" variant="light" size="sm" style={{marginLeft:7}} /> }
                </Button>
            </div>
        )
    }

    const confirmView = () => {
        return (
            <div>
                <div className="mb-2 text-start" style={{fontWeight:700}}>Select Address:</div>
                <div className="mb-5">
                    <ListGroup defaultActiveKey={0}>
                        {
                            address.map((val, idx) => {
                                return (
                                    <ListGroup.Item action key={idx} eventKey={idx} onClick={() => {setSelectedAddressKey(idx)}} disabled={loading}>
                                        <span>{mergeAddress(val)}</span>
                                    </ListGroup.Item>
                                )
                            })
                        }
                    </ListGroup>
                </div>
                <div className="mb-4">
                    <BootstrapButton
                        variant="link"
                        onClick={() => {handleCloseModal()}}
                        className={css`
                            text-decoration: none;
                            color: #666;
                            padding: 0 4px;
                            border-radius: 0;
                            border-bottom: 1px dashed #666;
                            :hover{
                                color: #38c3a8;
                                border-bottom-color: #38c3a8;
                            }
                        `}
                        disabled={loading}
                    >Enter Address Manually</BootstrapButton>
                </div>
                <Button onClick={handleSearch}>
                    Seach Plans
                    { loading && <Spinner animation="border" variant="light" size="sm" style={{marginLeft:7}} /> }
                </Button>
            </div>
        )
    }

    const handleCloseModal = () => {
        setView('upload');
        setFiles(undefined);
        handleClose();
    }

    const mergeAddress = (address: any) => {
        let fullAddress = '';
        if (address.house_number) {
            fullAddress += address.house_number
        }
        if (address.road) {
            fullAddress += (' ' + address.road)
        }
        if (address.unit) {
            fullAddress += (' ' + address.unit)
        }
        if (address.city) fullAddress += (', ' + address.city)
        if (address.state) fullAddress += (', ' + address.state)
        if (address.postcode) fullAddress += (', ' + address.postcode.split('-')[0]);

        return fullAddress;
    }

    return (
        <Modal size="lg" show={show} onHide={() => {handleCloseModal()}}>
            <Modal.Header closeButton>
                <Modal.Title>Upload a recent bill</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>With a recent electric bill, our technology automatically forecasts your home's electricity usage based on zip code and home size and then sorts through hundreds of plans to find the best one for you</p>
                <div className="text-center mt-4 mb-5">
                    { view === 'upload' ? uploadView() : confirmView() }
                </div>
                <p className={css`
                    font-size: 13px;
                    color: #aaa;
                    line-height: 1.1;
                    margin-bottom: 0;
                `}>Please click "Upload" to indicate you have read and agree to the Terms of Service &amp; Privacy Policy</p>
            </Modal.Body>
        </Modal>
    )
}