import React from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import qs from 'qs';
import { GetUsageComponent } from "./components/get_usage";
import Header from './components/header';

export const GetUsage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { address, esiid, zipcode, city, state, source, utcode } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [ cookies, setCookie, removeCookie ] = useCookies(['consent_id']);

    return (
        <>
            <Header />
            <div className="container-md">
                <GetUsageComponent
                    defaultView={source as string}
                    fetchActualUsage={() => {
                        navigate(`/plans?address=${encodeURIComponent(`${address}`)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utcode=${utcode}`);
                    }}
                    handleSetConsentId={(id: string) => {
                        setCookie('consent_id', id, { path: '/', maxAge: 3600 });
                    }}
                    zipcode={`${zipcode}`}
                    forcastUsage={() => {
                        navigate(`/plans?address=${encodeURIComponent(`${address}`)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utcode=${utcode}`);
                    }}
                />
            </div>
        </>
    )
}