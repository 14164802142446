import styled from 'styled-components';

interface ButtonProps {
    variant?: string;
    md?: boolean;
    disabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
    background: ${props => props.disabled ? (props.variant === 'secondary' ? '#fff' : '#38c3a8') : (props.variant === 'secondary' ? '#fff' : '#38c3a8')};
    color: ${props => props.variant === 'secondary' ? '#777' : '#fff'};
    border: ${props => props.variant === 'secondary' ? '1px solid #eee' : '0 none'};
    font-weight: 600;
    font-size: ${props => props.md ? '14px' : '16px'};
    padding: ${props => props.md ? '8px 16px' : '12px 32px'};
    border-radius: 40px;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    transition: opacity .3s;
    text-transform: uppercase;
    :hover{
        opacity: ${props => props.disabled ? '1' : '.85'};
    }
`