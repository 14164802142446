/**
 * Renders an RGB GeoTiff image into an HTML canvas.
 *
 * The GeoTiff image must include 3 rasters (bands) which
 * correspond to [Red, Green, Blue] in that order.
 *
 * @param  {GeoTiff} rgb   GeoTiff with RGB values of the image.
 * @param  {GeoTiff} mask  Optional mask for transparency, defaults to opaque.
 * @return {HTMLCanvasElement}  Canvas element with the rendered image.
 */

export interface Bounds {
  north: number;
  south: number;
  east: number;
  west: number;
}

export interface GeoTiff {
  width: number;
  height: number;
  rasters: Array<number>[];
  bounds: Bounds;
}

export function renderRGB(rgb: GeoTiff, mask?: GeoTiff): HTMLCanvasElement {
  // Create an HTML canvas to draw the image.
  // https://www.w3schools.com/tags/canvas_createimagedata.asp
  const canvas = document.createElement("canvas");

  // Set the canvas size to the mask size if it's available,
  // otherwise set it to the RGB data layer size.
  canvas.width = mask ? mask.width : rgb.width;
  canvas.height = mask ? mask.height : rgb.height;

  // Since the mask size can be different than the RGB data layer size,
  // we calculate the "delta" between the RGB layer size and the canvas/mask
  // size. For example, if the RGB layer size is the same as the canvas size,
  // the delta is 1. If the RGB layer size is smaller than the canvas size,
  // the delta would be greater than 1.
  // This is used to translate the index from the canvas to the RGB layer.
  const dw = rgb.width / canvas.width;
  const dh = rgb.height / canvas.height;

  // Get the canvas image data buffer.
  const ctx = canvas.getContext("2d")!;
  const img = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // Fill in every pixel in the canvas with the corresponding RGB layer value.
  // Since Javascript doesn't support multidimensional arrays or tensors,
  // everything is stored in flat arrays and we have to keep track of the
  // indices for each row and column ourselves.
  for (let y = 0; y < canvas.height; y += 1) {
    for (let x = 0; x < canvas.width; x += 1) {
      // RGB index keeps track of the RGB layer position.
      // This is multiplied by the deltas since it might be a different
      // size than the image size.
      const rgbIdx = Math.floor(y * dh) * rgb.width + Math.floor(x * dw);
      // Mask index keeps track of the mask layer position.
      const maskIdx = y * canvas.width + x;

      // Image index keeps track of the canvas image position.
      // HTML canvas expects a flat array with consecutive RGBA values.
      // Each value in the image buffer must be between 0 and 255.
      // The Alpha value is the transparency of that pixel,
      // if a mask was not provided, we default to 255 which is opaque.
      const imgIdx = y * canvas.width * 4 + x * 4;

      img.data[imgIdx + 0] = rgb.rasters[0][rgbIdx]; // Red
      img.data[imgIdx + 1] = rgb.rasters[1][rgbIdx]; // Green
      img.data[imgIdx + 2] = rgb.rasters[2][rgbIdx]; // Blue
      img.data[imgIdx + 3] = mask // Alpha
        ? mask.rasters[0][maskIdx] * 255
        : 255;
    }
  }

  // Draw the image data buffer into the canvas context.
  ctx.putImageData(img, 0, 0);
  return canvas;
}
