import React from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { IS_DEV } from './config'
import { Homepage } from './homepage'
import { Plans } from './plans'
import { GetUsage } from './get_usage'
import { Enrollment } from './enrollment'
import Footer from './components/footer'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import 'react-datetime/css/react-datetime.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import qs from 'qs'
import {
    faFilePdf,
    faTimesCircle,
    faSearch,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faSquare,
    faBars,
    faArrowDown,
    faTimes,
    faSlidersH,
    faCaretUp,
    faCaretDown,
    faExchangeAlt,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'
import './i18n'
import { SavingCalculation } from './pages/saving-calculation'
import TermsOfService from './pages/terms_of_service'
import PrivacyPolicy from './pages/privacy_policy'
import FAQs from './pages/faqs'
import { AboutUs } from './pages/about-us'
import { ContactUs } from './pages/contact-us'

library.add(
    faFilePdf as any,
    faTimesCircle as any,
    faSearch as any,
    faCheckCircle as any,
    faCheckSquare as any,
    faCircle as any,
    faSquare as any,
    faBars as any,
    faArrowDown as any,
    faTimes as any,
    faSlidersH as any,
    faCaretUp as any,
    faCaretDown as any,
    faExchangeAlt as any,
    faMapMarkerAlt as any
)

const theme = {
    colors: {
        primary: '#38c3a8',
    },
}

function App() {
    const { promo_code } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const [ cookies, setCookie ] = useCookies(['promo_code']);
    
    if (promo_code) {
        setCookie('promo_code', promo_code, { path: '/', maxAge: 1209600 });
    }
    
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter basename={`${IS_DEV ? '/dev' : '/'}`}>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/plans" element={<Plans />} />
                    <Route path="/about_us" element={<AboutUs />} />
                    <Route path="/get_usage" element={<GetUsage />} />
                    <Route path="/enrollment" element={<Enrollment />} />
                    <Route path="/saving_calculation" element={<SavingCalculation />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                    <Route path="/faqs" element={<FAQs />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                </Routes>
            </BrowserRouter>
            <Footer />
            <ToastContainer />
        </ThemeProvider>
    )
}

export default App
