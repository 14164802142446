
export const fetchGetSolarRoofImg = async (address, lat, lng) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer c346b9eb4d8f1161d01cf7c88e66c181`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const encodedAddress = encodeURIComponent(address);
  const url = `https://dev.powerlego.com/ApiGateway/v2/solarroof/get_pic?term=${encodedAddress}&lat=${lat}&lng=${lng}`;

  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchPostSolarRoofImg = async (
  address,
  lat,
  lng,
  solarRoofImg,
  bounds
) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer c346b9eb4d8f1161d01cf7c88e66c181`);

  const formdata = new FormData();
  formdata.append("term", address);
  formdata.append("lat", lat);
  formdata.append("lng", lng);
  formdata.append("solar_pic", solarRoofImg);
  formdata.append("bounds", bounds);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const url = `https://dev.powerlego.com/ApiGateway/v2/solarroof/upload_pic`;

  const response = await fetch(url, requestOptions);
  return response.json();
};

