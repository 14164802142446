import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import type { PlanRowProps } from '../types';
import { Button } from '../components/styles/Button.styled';
import { Modal, Button as BootstrapButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MonthlyBreakdownTable } from './monthly-breakdown-table';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { IS_DEV } from '../config';
import { PUCT_number } from '../common/data';

export const PlanCard: React.FC<PlanRowProps> = ({
  val,
  usage,
  hidePrice,
  handleShowAccessSmtSection,
  handleCompare,
  compare,
  handleClickEnroll,
  avgPrice,
  handleShowSidebySideModal,
  isRecommend,
  isSponsored,
}) => {
  const { t, i18n } = useTranslation('plan');
  const {
    plan_name,
    contract_term,
    base_rate,
    total,
    total_usage,
    rate,
    renewable,
    cancel_fee,
    cancel_fee_type,
    plan_desc,
    terms_url,
    efl_url,
    provider_id,
    month,
    is_prepaid,
    plan_id,
    yrac,
    type,
    source
  } = val;

  const [showDetail, setShowDetail] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleShowDetail = () => setShowModal(!showModal);
  const is100Green = parseInt(renewable) === 100;

  let saving = 0;
  if (avgPrice !== '0') {
    saving = (parseFloat(avgPrice) - rate / 100) / parseFloat(avgPrice);
  }

  saving = Math.round(saving * 10000) / 100;
  const advertisement = () => {
    window.open('https://s24alarms.com/leb')
  }

  return (<>
    <div className={`plan-row-wrapper  ${(isRecommend || isSponsored) ? 'plan-row-wrapper-recommend' : ''}`} style={{ overflow: 'visible',marginTop:0 }}>
      <div className="plan-row" style={{ display: 'inline-block', width: '100%', position: 'relative' }}>
        <div style={{
          position: 'absolute',
          transform: 'translate(-50%, 0)',
          left: '50%',
          whiteSpace: 'nowrap',
          top: '-14px',
          background: '#fff',
        }}>
          <span style={{
            background: 'linear-gradient(135deg, #17C2C4 0%, #30D886 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}>
            Best-Rated <span style={{fontWeight:'bold'}}> {isSponsored ? 12 : 24}-Month </span> Plan
          </span>
        </div>
        {/* {isSponsored && <i className='plan-recommend'>{t("Sponsored Plan")}</i>}
        {isRecommend && <i className='plan-recommend'>{t("LowerEBill Recommended Plan")}</i>} */}
        <div className="plan-content d-flex flex-column justify-content-between">
          <div className="d-flex flex-column flex-grow-1">
            <div className="rep-logo-card">
              <img src={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${provider_id}`} alt="" />
            </div>
            <div className="d-flex flex-column">
              <div className="plan-info">
                <div className="plan-est-rate d-flex align-items-center justify-content-center">
                  {hidePrice ? 'N/A' : `${rate}¢`}
                  {
                    !hidePrice &&
                    <div className="ms-2" style={{ fontSize: 0 }}>
                      <i data-tip={t("The Total Est. Rate has factored in your usage pattern, tiered energy rates, TDU delivery fee, basic service charge, bill credit/rebate and other recurring charges if applicable")} className="tooltip-mark">?</i>
                      <ReactTooltip className="tooltip" effect="solid" />
                    </div>
                  }
                </div>
                <label>{t('Total Est. Rate/kWh')}</label>
              </div>
              {
                saving > 0 &&
                <div className="plan-saving">
                  <a href={i18n.language === 'cn_zh' ? `${IS_DEV ? '/dev' : ''}/saving_calculation_cn` : `${IS_DEV ? '/dev' : ''}/saving_calculation`} target="_blank" rel="noreferrer">{t('Saving')}: {saving}%</a>
                </div>
              }
              <div className="text-center" style={{ fontSize: 13, color: '#999' }}>{t('Cancellation Fee')}: {cancel_fee}{cancel_fee_type === 'PerMonth' ? ` (${t('Per Remaining Month')})` : ''}</div>
            </div>
            <div className="plan-overview mb-4">
              <div className="plan-info-group" style={{ flexDirection: 'column' }}>
                <div className="plan-info-card plan-info-terms">
                  <div>{contract_term} <span>{t('Months')}</span></div>
                  <label>{t('Plan Length')}</label>
                </div>
                <div className="plan-info-card">
                  <div>{(parseFloat(base_rate) * 100).toFixed(2)}¢</div>
                  <label>{t('Base Rate')}</label>
                </div>
                <div className="plan-info-card">
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>{hidePrice ? 'N/A' : <NumberFormat value={(total / parseInt(contract_term)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix="$" />}</div>
                  <label>{t('Est. Avg. Monthly Cost')}</label>
                </div>
              </div>
            </div>
            <div>
              <div className="plan-name">{plan_name}</div>
              <div className="plan-tags d-flex align-items-center flex-wrap mt-2 mb-3" style={{ height: 24.5 }}>
                {is100Green && <span>{t('100% Green')}</span>}
                {is_prepaid === '1' && <span>{t('Prepaid')}</span>}
              </div>
            </div>
          </div>
          {/* {source && <div>Source: {" " } {source} </div>} */}
          <div className="d-flex justify-content-center">
            {
              !hidePrice &&
              <Button className="ps-2 pe-2" onClick={() => {
                // if (type === 'broker') {
                //     localStorage.setItem('ts_plan', JSON.stringify(val));
                //     handleClickEnroll(plan_id);
                // }
                // else {
                //     handleShowSidebySideModal(val);
                // }
                if (type === 'broker') {
                  localStorage.setItem('ts_plan', JSON.stringify(val));
                  handleClickEnroll(plan_id);
              }
              else {
                  handleShowSidebySideModal(val);
              }
              }}>{t('Enroll Now')}</Button>
            }
            {
              hidePrice &&
              <Button onClick={() => handleShowAccessSmtSection()}>{t('Get Rate')}</Button>
            }
          </div>
          <div className="mt-3 d-flex justify-content-between">
            <BootstrapButton
              variant="light"
              size="sm"
              onClick={() => {
                handleShowDetail();
              }}
              style={{ border: '1px solid #ddd', backgroundColor: '#fff' }}
            >
              <span className="me-2">{t(`${showDetail ? 'Hide' : 'Show'} Detail`)}</span>
              <FontAwesomeIcon icon={showDetail ? "caret-up" : "caret-down"} />
            </BootstrapButton>
            <BootstrapButton
              variant="light"
              size="sm"
              className={`ms-2 plan-compare-btn ${compare ? 'active' : ''}`}
              onClick={() => handleCompare()}
            >
              <FontAwesomeIcon icon="exchange-alt" className="me-2" />
              {t('Compare')}
            </BootstrapButton>
          </div>
        </div>
      </div>
      <div className={`plan-detail ${showDetail ? 'active' : ''}`}>
        <div className="pt-4 pb-4">
          <div className="plan-detail-heading">{t('Estimated Annualized Cost')}</div>
          {
            !hidePrice &&
            <div className="mb-2">
              {t('Total Est. Cost')}: <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix="$" /> ({t('Based on')} <NumberFormat value={total_usage} displayType={'text'} suffix={'kWh'} thousandSeparator={true} />)
            </div>
          }
          <MonthlyBreakdownTable usage={usage} monthlyPrice={month} hidePrice={hidePrice} />
          <div className="plan-detail-heading">{t('Plan Details')}</div>
          <div>{plan_desc}</div>
          <div className="mt-4">
            <div>{t('Renewable Percentage')}: {renewable}%</div>
          </div>
          <div className="plan-links mt-3">
            {
              terms_url && <a href={terms_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">{t('Terms of Service')}</span></a>
            }
            {
              efl_url && <a href={efl_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">{t('Facts Label')}</span></a>
            }
            {
              yrac && <a href={yrac} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">YRAC</span></a>
            }
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleShowDetail} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{t('Plan Details')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pt-4 pb-4">
            <div className="plan-detail-heading">{t('Estimated Annualized Cost')}</div>
            {
              !hidePrice &&
              <div className="mb-2">
                {t('Total Est. Cost')}: <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix="$" /> ({t('Based on')} <NumberFormat value={total_usage} displayType={'text'} suffix={'kWh'} thousandSeparator={true} />)
              </div>
            }
            <MonthlyBreakdownTable usage={usage} monthlyPrice={month} hidePrice={hidePrice} />
            <div className="plan-detail-heading">{t('Plan Details')}</div>
            <div>{plan_desc}</div>
            <div className="mt-4">
              <div>{t('Renewable Percentage')}: {renewable}%</div>
            </div>
            <div className="plan-links mt-3">
              {
                terms_url && <a href={terms_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">{t('Terms of Service')}</span></a>
              }
              {
                efl_url && <a href={efl_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">{t('Facts Label')}</span></a>
              }
              {
                yrac && <a href={yrac} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">YRAC</span></a>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton variant="secondary" onClick={handleShowDetail}>
            {t('Close')}
          </BootstrapButton>
        </Modal.Footer>
      </Modal>
    </div>
  </>
  )
}