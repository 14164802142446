export const mockTouUsageData = {
    "1": {"free": 200, "charge": 300},
    "2": {"free": 200, "charge": 300},
    "3": {"free": 200, "charge": 300},
    "4": {"free": 200, "charge": 300},
    "5": {"free": 200, "charge": 300},
    "6": {"free": 200, "charge": 300},
    "7": {"free": 200, "charge": 300},
    "8": {"free": 200, "charge": 300},
    "9": {"free": 200, "charge": 300},
    "10": {"free": 200, "charge": 300},
    "11": {"free": 200, "charge": 300},
    "12": {"free": 200, "charge": 300}
};