import styled from 'styled-components';

interface RowProps {
    reverse?: boolean;
}

export const SectionRow = styled.div<RowProps>`
    display: flex;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    align-items: center;
    padding: 44px 0;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const SectionContent = styled.div`
    padding: 0 44px;

    h2 {
        margin-bottom: 24px;
        font-weight: 700;
    }

    p {
        color: #666;
    }

    @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
`

export const SectionImg = styled.div`
    flex-shrink: 0;
    max-width: 45%;
    
    img {
        max-width: 100%;
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
`

export const HomepageTopSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`

export const HomepageTopSectionMain = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: 44px 0px;

    h1 {
        font-size: 62px;
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 20px;
    }

    p {
        font-size: 17px;
        color: #666;
    }

    @media (max-width: 991px) {
        h1 {
            font-size: 45px;
        }
    }

    @media (max-width: 767px) {
        width: 100%;
    }
`
