import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'

export const AboutUs = () => {
    return (
        <>
            <Header />
            <ContentPage>
                <Container>
                    <h1>About Us</h1>
                    <h2>Who is EIQshopping?</h2>
                    <p>
                        EIQshopping specializes in helping you find the ideal energy plan tailored to your specific needs. 
                        Leveraging the deep expertise of professionals from the energy and technology sectors, we simplify 
                        the process of selecting
                    </p>
                    <h2>How is EIQshopping different? Why choose us for your energy supplier and electricity plan?</h2>
                    <p>
                        EIQshopping sets itself apart from other energy brokers by offering a more comprehensive and accurate selection process. Unlike others that rely on general usage estimates like 500/1000/2000 kWh, we delve into your home’s unique energy consumption patterns. Our advanced AI analyzes thousands of plans daily, forecasting costs with precision by considering factors like tiered rates, seasonal changes, and other hidden charges. This means you get a tailored plan recommendation that’s truly based on your actual needs, helping you avoid unpleasant surprises on your bill—all without any cost to you.
                    </p>
                    <p>
                        LowerEbill.com fixed this mess. Our AI engine forecasts your cost based on
                        historical usage pattern and takes into consideration of tiered rates,
                        seasonal fluctuation, night and weekend discount, as well as additional fee
                        or credit. Our personalized plan recommendations are tailored for your home.
                    </p>
                    <h2>I entered my address, but I do not see energy offers available in my area, why not?</h2>
                    <p>
                        EIQshopping displays energy plans only in deregulated markets, where consumers have the freedom to choose their energy provider. Although we are rapidly expanding, there are still areas where our service isn’t available. If you’re in a deregulated market but don’t see any offers yet, it’s likely that we haven’t reached your area just yet. Check back soon as we continue to grow our coverage. 
                    </p>
                </Container>
            </ContentPage>
        </>
    )
}
