import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'
import {
    EnrollmentContainer,
    EnrollmentMain,
    EnrollmentAside,
    AsideBox,
    AsideBoxHeader,
    AsideBoxBody,
    PlanSummaryRow,
    PlanSummaryPromote
} from './components/styles/Enrollment.styled'
import { ProcessBar } from './components/enrollment/ProcessBar'
import { EnrollmentStepService } from './components/enrollment/StepService'
import { EnrollmentStepInfo } from './components/enrollment/StepInfo'
import { EnrollmentStepConfirmation } from './components/enrollment/StepConfirmation'
import { EnrollmentStepReview } from './components/enrollment/StepReview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { PlanProps } from './types'
import { toast } from 'react-toastify'
import NumberFormat from 'react-number-format'
import { getEnrollmentInputs } from './api/enrollment'
import Header from './components/header'
import { PUCT_number } from './common/data'

export const Enrollment = () => {
    const { t } = useTranslation('enrollment')
    const navigate = useNavigate()
    const location = useLocation()
    const { address, esiid, zipcode, city, state, plan_id, utility_code } = qs.parse(
        location.search, {
            ignoreQueryPrefix: true,
        })
    const [step, setStep] = useState(1)
    const [enrollmentId, setEnrollmentId] = useState('')
    const [plan, setPlan] = useState<PlanProps | undefined>(undefined)
    const [enrollmentResult, setEnrollmentResult] = useState(undefined)
    const [dynamicFields, setDynamicFields] = useState(undefined)
    const [sign, setSign] = useState('')
    const [specialPro, setSpecialPro] = useState({ ssn: '', date_of_birth: '' })
    const [serviceType, setServiceType] = useState<string>("1")

    useEffect(() => {
        if (!address || !esiid || !zipcode || !city || !state) {
            toast.error('Param is missing')
            return
        }

        const tsPlan = localStorage.getItem('ts_plan')
        if (tsPlan) {
            const planObj = JSON.parse(tsPlan)
            setPlan(planObj)
            if (planObj.plan_id.toLowerCase() !== `${plan_id}`.toLowerCase()) {
                toast.error('Plan is not match')
                navigate(-1)
                return
            }
            getEnrollmentInputs(planObj.provider_id).then((res) => {
                if (res && parseInt(res.status) === 1) {
                    setDynamicFields(res.response)
                } else {
                    console.error('Failed to fetch input fields')
                }
            })
        } else {
            toast.error('Plan is not exist')
            navigate(-1)
            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toStep = (stepName: string) => {
        navigate(
            `/enrollment?plan_id=${plan_id}&address=${encodeURIComponent(
                `${address}`
            )}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utility_code=${utility_code}&step=${stepName}`
        )
    }

    return (
        <div>
            <Header />
            <Container>
                <EnrollmentContainer>
                    <EnrollmentMain>
                        <ProcessBar step={step} />
                        <EnrollmentStepService
                            address={`${address}`}
                            zipcode={`${zipcode}`}
                            city={`${city}`}
                            state={`${state}`}
                            esiid={`${esiid}`}
                            show={step === 1}
                            handleContinue={(id: string, sign: string,serviceType:string) => {
                                setServiceType(serviceType)
                                setEnrollmentId(id)
                                setSign(sign)
                                setStep(2)
                                toStep('information')
                            }}
                            product={plan ? plan.product : ''}
                        />
                        <EnrollmentStepInfo
                            address={`${address}`}
                            zipcode={`${zipcode}`}
                            city={`${city}`}
                            state={`${state}`}
                            show={step === 2}
                            handleBack={() => {
                                setStep(1)
                                toStep('service_date')
                            }}
                            handleContinue={(val: any) => {
                                setSpecialPro(val)
                                setStep(3)
                                toStep('review')
                            }}
                            enrollmentId={enrollmentId}
                            dynamicFields={dynamicFields}
                            product={plan ? plan.product : ''}
                            rep_code={(plan && plan.rep_code) ? plan.rep_code : ''}
                            sign={sign}
                            specialPro={specialPro}
                            serviceType={serviceType}
                        />
                        <EnrollmentStepReview
                            show={step === 3}
                            enrollmentId={enrollmentId}
                            handleContinue={(val: any) => {
                                setEnrollmentResult(val)
                                setStep(4)
                                toStep('submit')
                            }}
                            handleBack={() => {
                                setStep(2)
                                toStep('information')
                            }}
                            sign={sign}
                            specialPro={specialPro}
                            handleSSN={(val: any) => {
                                val.ssn = ''
                                setSpecialPro(val)
                                setStep(4)
                            }}
                        />
                        <EnrollmentStepConfirmation
                            result={enrollmentResult}
                            show={step === 4}
                            sign={sign}
                            data={plan}
                        />
                    </EnrollmentMain>
                    <EnrollmentAside>
                        <AsideBox>
                            <AsideBoxHeader>
                                <h5>{t('Your Selected Electric Plan')}</h5>
                            </AsideBoxHeader>
                            <AsideBoxBody>
                                <PlanReview data={plan} />
                            </AsideBoxBody>
                        </AsideBox>
                    </EnrollmentAside>
                </EnrollmentContainer>
            </Container>
        </div>
    )
}

interface PlanReviewProps {
    data?: PlanProps
}

const PlanReview: React.FC<PlanReviewProps> = ({ data }) => {
    const { t } = useTranslation('plan')
    if (!data) return null
    const {
        plan_name,
        contract_term,
        cancel_fee,
        cancel_fee_type,
        terms_url,
        efl_url,
        provider_id,
        yrac,
        price1000,
        rate,
    } = data

    return (
        <>
            <div className="d-flex align-items-center mb-5">
                <div className="rep-logo me-3">
                    <img src={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${provider_id}`} alt="" />
                    {
                        PUCT_number[provider_id] &&
                        <i>PUCT# {PUCT_number[provider_id]}</i>
                    }
                </div>
                <div className="plan-name">{plan_name}</div>
            </div>
            <div>
                <PlanSummaryRow>
                    <label>{t('Total Est. Rate/kWh')}:</label>
                    <span>
                        <b>{`${rate}¢`}</b>
                    </span>
                </PlanSummaryRow>
                <PlanSummaryRow>
                    <label>{t('Avg. Price Per kWh for 1000 kWh Usage')}:</label>
                    <NumberFormat
                        value={price1000}
                        displayType={'text'}
                        thousandSeparator={true}
                        suffix="¢"
                    />
                </PlanSummaryRow>
                <PlanSummaryRow>
                    <label>{t('Plan Length')}:</label>
                    <span>{contract_term} Months</span>
                </PlanSummaryRow>
                <PlanSummaryRow>
                    <label>{t('Cancellation Fee')}:</label>
                    <span>
                        {cancel_fee}
                        {cancel_fee_type === 'PerMonth' ? ` (${t('Per Remaining Month')})` : ''}
                    </span>
                </PlanSummaryRow>
            </div>
            <div className="plan-links mt-3 flex-column">
                {terms_url && (
                    <PlanSummaryRow narrow>
                        <a href={terms_url} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon="file-pdf" />
                            <span className="ms-2">{t('Terms of Service')}</span>
                        </a>
                    </PlanSummaryRow>
                )}
                {efl_url && (
                    <PlanSummaryRow narrow>
                        <a href={efl_url} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon="file-pdf" />
                            <span className="ms-2">{t('Facts Label')}</span>
                        </a>
                    </PlanSummaryRow>
                )}
                {yrac && (
                    <PlanSummaryRow narrow>
                        <a href={yrac} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon="file-pdf" />
                            <span className="ms-2">YRAC</span>
                        </a>
                    </PlanSummaryRow>
                )}
            </div>
            <PlanSummaryPromote>{t('Please read, print, and save these documents for your reference')}</PlanSummaryPromote>
        </>
    )
}
