import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    EnrollmentStepWrapper,
    EnrollmentStepFooter,
    EnrollmentFormRow,
    EnrollmentFormCol,
    CreditCheckSection,
    InputMaskToggle,
    InputWithMask
} from '../styles/Enrollment.styled'
import { Button } from '../styles/Button.styled'
import { Form, Dropdown, Spinner, Modal } from 'react-bootstrap'
import { CheckboxItem } from '../checkbox-item'
import { statesList } from '../../states-list'
import { FormError } from './FormError'
import { saveEnrollment, fetchAccountInformation, getProvider } from '../../api/enrollment'
import { toast } from 'react-toastify'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import Datetime from 'react-datetime'
import { LoadingRow } from '../loading-row'
import { useCookies } from 'react-cookie'
interface Props {
    show: boolean
    handleBack: Function
    handleContinue: Function
    enrollmentId: string
    address: string
    zipcode: string
    city: string
    state: string
    dynamicFields: any
    product: string
    sign: string
    specialPro: {
        ssn: any
        date_of_birth: any
    },
    rep_code: string
    serviceType: string
}

interface StatesOption {
    name: string
    abbr: string
}

export const EnrollmentStepInfo: React.FC<Props> = ({
    address,
    zipcode,
    city,
    state,
    show,
    handleBack,
    handleContinue,
    enrollmentId,
    dynamicFields,
    product,
    sign,
    specialPro,
    rep_code,
    serviceType
}) => {
    const { t } = useTranslation('enrollment')
    const [cookies] = useCookies(['promo_code']);
    const [inputValue, setInputValue] = useState<any>({
        first_name: { type: 'text', value: '' },
        last_name: { type: 'text', value: '' },
        email: { type: 'text', value: '' },
        email2: { type: 'text', value: '' },
        phone: { type: 'number', value: '' },
        billing_address: { type: 'text', value: '' },
        billing_address2: { type: 'text', value: '' },
        billing_city: { type: 'text', value: '' },
        billing_state: { type: 'select', value: '' },
        billing_zipcode: { type: 'text', value: '' },
        ext_contact_preference: { type: 'select', value: '' },
        ext_discover_from: { type: 'select', value: '' },
        ext_discover_from_desc: { type: 'text', value: '' },
        promo_code: { type: 'text', value: '' }
    })
    const [inputValueEzSwitch, setInputValueEzSwitch] = useState<any>({
        username: { type: 'text', value: '' },
        password: { type: 'number', value: '' },
        ischeck: { type: 'check', value: '' },
        provider: { type: 'select', value: '' },
    })
    const [isSameBillingAddress, setIsSameBillingAddress] = useState(true)
    const [billingState, setBillingState] = useState<StatesOption | undefined>(undefined)
    const [isAgree, setIsAgree] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorFields, setErrorFields] = useState<any>({})
    const [errorFieldsEZ, setErrorFieldsEZ] = useState<any>({})
    const [showSSN, setShowSSN] = useState(false);
    const [isEzSwitch, setIsEzSwitch] = useState(false);
    const [providerList, setProviderList] = useState<any[]>([]);
    const [requiredFields, setRequiredFields] = useState<any>({
        last_name: { type: 'text' },
        first_name: { type: 'text' },
        email: { type: 'email' },
        email2: { type: 'email' },
        phone: { type: 'number' },
    })
    const preferredContactMethodOptions = [
        { label: `${t('Please Select')}...`, value: '', required: false },
        { label: t('Phone'), value: 'Phone', required: true },
        { label: t('Email'), value: 'Email', required: true },
    ]

    const surveyOptions = [
        { label: `${t('Please Select')}...`, value: '', required: false },
        { label: 'Google Search', value: 'Google Search', required: false },
        { label: 'Facebook', value: 'Facebook', required: false },
        { label: 'Instagram', value: 'Instagram', required: false },
        { label: t('Family/Friends'), value: 'Family/Friends', required: false },
        {
            label: t('Professional Association (please specify)'),
            value: 'Professional Association',
            required: true,
        },
        { label: t('Others (please specify)'), value: 'Others', required: true },
    ]

    useEffect(() => {
        getProvider({ state_id: 'TX' }).then((res) => {
            if (res.status === 1) {
                let list = res.response.data['TX']
                list = list.filter((item:any)=>{ return item.available})
                setProviderList(list)
            }
        })
    }, [])

    useEffect(() => {
        if (dynamicFields) {
            let newInputValue = Object.assign({}, inputValue),
                newRequiredFields: any = Object.assign({}, requiredFields)

            for (let cate in dynamicFields) {
                for (let key in dynamicFields[cate]) {
                    const item = dynamicFields[cate][key]
                    const { type } = item
                    if (key === 'ssn') {
                        newInputValue[key] = { type: 'number', value: '' }
                    } else {
                        if (type === 'checkbox') {
                            newInputValue[key] = { type: type, value: false }
                        } else if (type === 'date' || type === 'select') {
                            newInputValue[key] = { type: type, value: undefined }
                        } else {
                            newInputValue[key] = { type: type, value: '' }
                        }
                    }
                    newRequiredFields[key] = { type: type }
                }
            }
            if (cookies && cookies.promo_code) {
                newInputValue.promo_code.value = cookies.promo_code;
            }
            setInputValue(newInputValue)
            setRequiredFields(newRequiredFields)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicFields])

    const validateEvSwitch = () => {
        let isValid = true
        let newErrorFields: any = {}
        if (!inputValueEzSwitch.username.value) {
            newErrorFields['username'] = t('Username is required.');
            isValid = false
        }
        if (!inputValueEzSwitch.password.value) {
            newErrorFields['password'] = t('Password is required.');
            isValid = false
        }

        if (!inputValueEzSwitch.ischeck.value) {
            newErrorFields['ischeck'] = t('This field is required')
            isValid = false
        }

        if (!inputValueEzSwitch.provider.value) {
            newErrorFields['provider'] = t('This field is required')
            isValid = false
        }

        setErrorFieldsEZ(newErrorFields)
        return isValid
    }

    const validate = () => {
        let isValid = true
        let newErrorFields: any = {}

        for (let i in requiredFields) {
            const { type } = requiredFields[i]
            if (type === 'checkbox') {
                continue
            } else if (type === 'date') {
                if (
                    !inputValue[i].value ||
                    !(
                        moment(inputValue[i].value, 'MM/DD/YYYY', true).isValid() ||
                        moment(inputValue[i].value, 'M/DD/YYYY', true).isValid() ||
                        moment(inputValue[i].value, 'MM/D/YYYY', true).isValid() ||
                        moment(inputValue[i].value, 'M/D/YYYY', true).isValid()
                    )
                ) {
                    newErrorFields[i] = t('Invalid Date')
                    isValid = false
                }
            } else if (type === 'email') {
                // eslint-disable-next-line
                const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                if (!pattern.test(inputValue[i].value)) {
                    newErrorFields[i] = t('Invalid emaill address format')
                    isValid = false
                }
            } else {
                if (!inputValue[i].value) {
                    newErrorFields[i] = t('This field is required')
                    isValid = false
                }
            }
        }

        if (inputValue.phone.value.indexOf('_') >= 0) {
            newErrorFields['phone'] = t('Invalid Phone Number')
            isValid = false
        }

        if (inputValue.ssn.value.indexOf('_') >= 0) {
            newErrorFields['ssn'] = t('Invalid SSN format')
            isValid = false
        }

        if (inputValue.email.value && inputValue.email2.value) {
            if (inputValue.email.value !== inputValue.email2.value) {
                newErrorFields['email'] = t('Email address does not match')
                newErrorFields['email2'] = t('Email address does not match')
                isValid = false
            }
        }

        if (!isSameBillingAddress && !billingState) {
            newErrorFields['billingState'] = t('This field is required')
            isValid = false
        }

        if (!isAgree) {
            newErrorFields['isAgree'] = t('This field is required')
            isValid = false
        }

        setErrorFields(newErrorFields)
        return isValid
    }
    const splitFullName = (fullName: string) => {
        const nameParts = fullName.trim().split(" ");

        if (nameParts.length === 1) {
            return { firstName: nameParts[0], lastName: "" };
        }

        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(" ");

        return { firstName, lastName };
    };
    const EzSwitchSubmit = async () => {
        if (loading || !validateEvSwitch()) return

        setLoading(true);

        const params = {
            utility_code: inputValueEzSwitch.provider.value.utility_code,
            username: inputValueEzSwitch.username.value,
            password: inputValueEzSwitch.password.value,
            state_id: "TX",
            need_encrypt: false,
        };
        let response = await fetchAccountInformation(params);
        if(response.status != 1){
            toast.error(response.msg)
            setLoading(false);
            return
        }
        if(response.response.details.code === '500'){
            toast.error("The account data cannot be synchronized. Please check if the account can be logged in on the official website.")
            setLoading(false);
            return
        }
        let newInputValue = Object.assign({}, inputValue)
        const { firstName, lastName } = splitFullName(response.response.details.name);
        newInputValue['first_name'].value = firstName ? firstName : ''
        newInputValue['last_name'].value = lastName ? lastName : ''
        newInputValue['email'].value = response.response.details.email ? response.response.details.email : ''
        newInputValue['email2'].value = response.response.details.email ? response.response.details.email : ''
        newInputValue['phone'].value = response.response.details.phone ? response.response.details.phone : ''
        setInputValue(newInputValue)
        setLoading(false);
        setIsEzSwitch(false)
    };

    const handleSave = () => {
        if (loading || !validate()) return  
        setLoading(true)

        let saveFields: any = {
            product: product,
        }

        const serviceAddress: any = {
            address: address,
            state: state,
            city: city,
            zipcode: zipcode,
        }

        let trueFields: any = {}

        for (let i in inputValue) {
            if (i === 'email2' || i === 'billing_address2') continue

            const item = inputValue[i]
            if (i.indexOf('billing_') >= 0) {
                const _key = i.substr(8)
                if (i === 'billing_address') {
                    const billingAddress = `${item.value} ${inputValue.billing_address2.value}`
                    saveFields[i] = isSameBillingAddress
                        ? serviceAddress[_key]
                        : billingAddress.trim()
                } else {
                    saveFields[i] = isSameBillingAddress
                        ? serviceAddress[_key]
                        : inputValue[`billing_${_key}`].value
                }
            } else {
                if (item.type === 'number') {
                    saveFields[i] = item.value ? item.value.replace(/ /g, '') : ''
                } else if (item.type === 'date') {
                    saveFields[i] = moment(item.value).format('MM/DD/YYYY')
                } else if (item.type === 'select') {
                    saveFields[i] = item.value ? item.value.value : ''
                } else {
                    saveFields[i] = item.value
                }
            }
        }

        for (const key in saveFields) {
            if (key === 'ssn' || key === 'date_of_birth') {
                specialPro[key] = saveFields[key]
            } else {
                trueFields[key] = saveFields[key]
            }
        }
        saveEnrollment(enrollmentId, trueFields, sign).then((res) => {
            setLoading(false)
            if (res && parseInt(res.status) === 1) {
                handleContinue(specialPro)
            } else {
                toast.error(res.message)
            }
        })
    }

    const handleChangeInput = (evt: any) => {
        const { value, name } = evt.target

        let newInputValue = Object.assign({}, inputValue)
        newInputValue[name as any].value = value
        setInputValue(newInputValue)
    }

    const handleChangeInputEvSwitch = (evt: any) => {
        const { value, name } = evt.target

        let newInputValue = Object.assign({}, inputValueEzSwitch)
        newInputValue[name as any].value = value
        setInputValueEzSwitch(newInputValue)
    }

    const getDynamicFields = (type: string) => {
        const group = dynamicFields[type]

        return (
            <EnrollmentFormRow>
                {Object.keys(group).map((val, idx) => {
                    const item = group[val]
                    const { label, type } = item
                    if (type === 'checkbox') {
                        return (
                            <EnrollmentFormCol key={idx}>
                                <CheckboxItem
                                    label={t(label)}
                                    value={`${inputValue[val].value}`}
                                    checked={inputValue[val].value}
                                    handleClick={() => {
                                        let newInputValue = Object.assign({}, inputValue)
                                        newInputValue[val].value = !newInputValue[val].value
                                        setInputValue(newInputValue)
                                    }}
                                    onlyread={false}
                                    disabled={loading}
                                    id={label}
                                />
                            </EnrollmentFormCol>
                        )
                    } else if (type === 'select') {
                        return (
                            <EnrollmentFormCol key={idx}>
                                <Form.Group>
                                    <Form.Label>{t(label)} *</Form.Label>
                                    <Dropdown className="plans-filter-dd">
                                        <Dropdown.Toggle
                                            disabled={loading}
                                            variant="outline-secondary">
                                            {inputValue[val] && inputValue[val].value
                                                ? t(inputValue[val].value.label)
                                                : `${t('Please Select')}...`}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {(item.value as Object[]).map(
                                                (option: any, optionIdx) => {
                                                    return (
                                                        <Dropdown.Item
                                                            eventKey={option.label}
                                                            key={optionIdx}
                                                            onClick={() => {
                                                                let newInputValue = Object.assign(
                                                                    {},
                                                                    inputValue
                                                                )
                                                                newInputValue[val].value = option
                                                                setInputValue(newInputValue)
                                                            }}
                                                            active={
                                                                inputValue[val].value &&
                                                                inputValue[val].value.value ===
                                                                option.value
                                                            }>
                                                            {t(option.label)}
                                                        </Dropdown.Item>
                                                    )
                                                }
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                                <FormError message={errorFields[val]} />
                            </EnrollmentFormCol>
                        )
                    } else if (type === 'date') {
                        return (
                            <EnrollmentFormCol key={idx}>
                                <Form.Group>
                                    <Form.Label>{t(label)} *</Form.Label>
                                    <Datetime
                                        value={inputValue[val] ? inputValue[val].value : undefined}
                                        onChange={(date: any) => {
                                            let newInputValue = Object.assign({}, inputValue)
                                            newInputValue[val].value = date
                                            setInputValue(newInputValue)
                                        }}
                                        initialViewDate={moment().subtract(30, 'years')}
                                        dateFormat="MM/DD/YYYY"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        isValidDate={(currentDate) => {
                                            if (currentDate.isAfter(moment())) return false
                                            return true
                                        }}
                                        inputProps={{
                                            placeholder: t('MM/DD/YYYY'),
                                            disabled: loading,
                                        }}
                                    />
                                </Form.Group>
                                <FormError message={errorFields[val]} />
                            </EnrollmentFormCol>
                        )
                    } else {
                        if (val === 'ssn') {
                            return (
                                <EnrollmentFormCol key={idx}>
                                    <InputWithMask>
                                        <Form.Group>
                                            <Form.Label>{t('SSN')} *</Form.Label>
                                            <NumberFormat
                                                format="### ## ####"
                                                mask="_"
                                                name={val}
                                                value={inputValue[val].value}
                                                onChange={handleChangeInput}
                                                className="form-control"
                                                disabled={loading}
                                            />
                                            {!showSSN && (
                                                <NumberFormat
                                                    type="password"
                                                    maxLength={9}
                                                    name={val}
                                                    value={inputValue[val].value}
                                                    onChange={handleChangeInput}
                                                    className="form-control mask-input"
                                                    disabled={loading}
                                                />
                                            )}
                                        </Form.Group>
                                        <InputMaskToggle
                                            type="button"
                                            active={showSSN}
                                            onClick={() => setShowSSN(!showSSN)}
                                        />
                                    </InputWithMask>
                                    <FormError message={errorFields[val]} />
                                </EnrollmentFormCol>
                            )
                        }
                        return (
                            <EnrollmentFormCol>
                                <Form.Group>
                                    <Form.Label>{t(label)} *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={val}
                                        placeholder=""
                                        maxLength={50}
                                        value={inputValue[val].value}
                                        onChange={handleChangeInput}
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <FormError message={errorFields[val]} />
                            </EnrollmentFormCol>
                        )
                    }
                })}
            </EnrollmentFormRow>
        )
    }

    if (!show) return null
    if (!dynamicFields) {
        return (
            <div className="d-flex justify-content-center pt-5 pb-5">
                <LoadingRow />
            </div>
        )
    }
    return (
        <EnrollmentStepWrapper>
            {serviceType != "1" && <><h5>Want an Even Faster Registration?</h5>
                <p>Sync with <a style={{ color: 'rgb(56, 195, 168)', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setIsEzSwitch(true) }}>EzSwitch</a> now - simply log in to your existing energy provider account, 
                and we’ll automatically complete your registration details for you.</p>
                </>}
            <Form>
                <EnrollmentFormRow>
                    <EnrollmentFormCol>
                        <Form.Group>
                            <Form.Label>{t('First Name')} *</Form.Label>
                            <Form.Control
                                type="text"
                                name="first_name"
                                maxLength={50}
                                value={inputValue.first_name.value}
                                onChange={handleChangeInput}
                                disabled={loading}
                            />
                        </Form.Group>
                        <FormError message={errorFields['first_name']} />
                    </EnrollmentFormCol>
                    <EnrollmentFormCol>
                        <Form.Group>
                            <Form.Label>{t('Last Name')} *</Form.Label>
                            <Form.Control
                                type="text"
                                name="last_name"
                                maxLength={50}
                                value={inputValue.last_name.value}
                                onChange={handleChangeInput}
                                disabled={loading}
                            />
                        </Form.Group>
                        <FormError message={errorFields['last_name']} />
                    </EnrollmentFormCol>
                    <EnrollmentFormCol>
                        <Form.Group>
                            <Form.Label>{t('Email')} *</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder=""
                                maxLength={150}
                                value={inputValue.email.value}
                                onChange={handleChangeInput}
                                disabled={loading}
                            />
                        </Form.Group>
                        <FormError message={errorFields['email']} />
                    </EnrollmentFormCol>
                    <EnrollmentFormCol>
                        <Form.Group>
                            <Form.Label>{t('Confirm Email')} *</Form.Label>
                            <Form.Control
                                type="email"
                                name="email2"
                                placeholder=""
                                maxLength={150}
                                value={inputValue.email2.value}
                                onChange={handleChangeInput}
                                disabled={loading}
                            />
                        </Form.Group>
                        <FormError message={errorFields['email2']} />
                    </EnrollmentFormCol>
                    <EnrollmentFormCol>
                        <Form.Group>
                            <Form.Label>{t('Phone Number')} *</Form.Label>
                            <NumberFormat
                                format="### ### ####"
                                mask="_"
                                name="phone"
                                value={inputValue.phone.value}
                                onChange={handleChangeInput}
                                className="form-control"
                                disabled={loading}
                            />
                        </Form.Group>
                        <FormError message={errorFields['phone']} />
                    </EnrollmentFormCol>
                    <EnrollmentFormCol>
                        <Form.Group>
                            <Form.Label>{t('Promo Code')}</Form.Label>
                            <Form.Control
                                type="text"
                                name="promo_code"
                                placeholder=""
                                value={inputValue.promo_code.value}
                                onChange={handleChangeInput}
                                disabled={loading}
                            />
                        </Form.Group>
                    </EnrollmentFormCol>
                    <EnrollmentFormCol>
                        <Form.Group>
                            <Form.Label>{t('Where did you hear about us?')}</Form.Label>
                            <Dropdown className="plans-filter-dd">
                                <Dropdown.Toggle disabled={loading} variant="outline-secondary">
                                    {inputValue.ext_discover_from.value
                                        ? inputValue.ext_discover_from.value.label
                                        : `${t('Please Select')}...`}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {surveyOptions.map((val, idx) => (
                                        <Dropdown.Item
                                            eventKey={val.label}
                                            key={idx}
                                            onClick={() => {
                                                let newInputValue = Object.assign({}, inputValue),
                                                    newRequiredFields = Object.assign(
                                                        {},
                                                        requiredFields
                                                    )
                                                if (val.required) {
                                                    newRequiredFields['ext_discover_from_desc'] = {
                                                        type: 'text',
                                                    }
                                                } else {
                                                    if (newRequiredFields['ext_discover_from_desc'])
                                                        delete newRequiredFields[
                                                            'ext_discover_from_desc'
                                                        ]
                                                }
                                                newInputValue.ext_discover_from.value = val
                                                setRequiredFields(newRequiredFields)
                                                setInputValue(newInputValue)
                                            }}
                                            active={
                                                inputValue.ext_discover_from.value.value ===
                                                val.value
                                            }>
                                            {val.label}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </EnrollmentFormCol>
                    {inputValue.ext_discover_from && inputValue.ext_discover_from.value.required && (
                        <EnrollmentFormCol>
                            <Form.Group>
                                <Form.Label>{t('Please Specify')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="ext_discover_from_desc"
                                    placeholder=""
                                    value={inputValue.ext_discover_from_desc.value}
                                    onChange={handleChangeInput}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['ext_discover_from_desc']} />
                        </EnrollmentFormCol>
                    )}
                    <EnrollmentFormCol>
                        <Form.Group>
                            <Form.Label>{t('Preferred Method of Communication')}</Form.Label>
                            <Dropdown className="plans-filter-dd">
                                <Dropdown.Toggle disabled={loading} variant="outline-secondary">
                                    {inputValue.ext_contact_preference.value
                                        ? inputValue.ext_contact_preference.value.label
                                        : `${t('Please Select')}...`}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {preferredContactMethodOptions.map((val, idx) => (
                                        <Dropdown.Item
                                            eventKey={val.label}
                                            key={idx}
                                            onClick={() => {
                                                let newInputValue = Object.assign({}, inputValue)
                                                newInputValue.ext_contact_preference.value = val
                                                setInputValue(newInputValue)
                                            }}
                                            active={
                                                inputValue.ext_contact_preference.value.value ===
                                                val.value
                                            }>
                                            {val.label}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </EnrollmentFormCol>
                </EnrollmentFormRow>
                <EnrollmentFormRow>
                    <EnrollmentFormCol full>
                        <CheckboxItem
                            label={t('My billing address is the same as this service address')}
                            value={`${isSameBillingAddress}`}
                            checked={isSameBillingAddress}
                            handleClick={() => {
                                let newRequiredFields = Object.assign({}, requiredFields)
                                if (isSameBillingAddress) {
                                    newRequiredFields['billing_address'] = { type: 'text' }
                                    newRequiredFields['billing_city'] = { type: 'text' }
                                    newRequiredFields['billing_state'] = { type: 'select' }
                                    newRequiredFields['billing_zipcode'] = { type: 'text' }
                                } else {
                                    if (newRequiredFields['billing_address'])
                                        delete newRequiredFields['billing_address']
                                    if (newRequiredFields['billing_city'])
                                        delete newRequiredFields['billing_city']
                                    if (newRequiredFields['billing_state'])
                                        delete newRequiredFields['billing_state']
                                    if (newRequiredFields['billing_zipcode'])
                                        delete newRequiredFields['billing_zipcode']
                                }
                                setRequiredFields(newRequiredFields)
                                setIsSameBillingAddress(!isSameBillingAddress)
                            }}
                            onlyread={false}
                            disabled={loading}
                            id='is-same-address'
                        />
                    </EnrollmentFormCol>
                </EnrollmentFormRow>
                {!isSameBillingAddress && (
                    <div>
                        <EnrollmentFormRow>
                            <EnrollmentFormCol>
                                <Form.Group>
                                    <Form.Label>{t('Billing Address Line 1')} *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="billing_address"
                                        placeholder=""
                                        maxLength={200}
                                        value={inputValue.billing_address.value}
                                        onChange={handleChangeInput}
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <FormError message={errorFields['billing_address']} />
                            </EnrollmentFormCol>
                            <EnrollmentFormCol>
                                <Form.Group>
                                    <Form.Label>{t('Billing Address Line 2')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="billing_address2"
                                        placeholder=""
                                        maxLength={100}
                                        value={inputValue.billing_address2.value}
                                        onChange={handleChangeInput}
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <FormError message={errorFields['billing_address2']} />
                            </EnrollmentFormCol>
                            <EnrollmentFormCol>
                                <Form.Group>
                                    <Form.Label>{t('City')} *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="billing_city"
                                        placeholder=""
                                        maxLength={50}
                                        value={inputValue.billing_city.value}
                                        onChange={handleChangeInput}
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <FormError message={errorFields['billing_city']} />
                            </EnrollmentFormCol>
                            <EnrollmentFormCol>
                                <Form.Group>
                                    <Form.Label>{t('State')} *</Form.Label>
                                    <Dropdown className="plans-filter-dd">
                                        <Dropdown.Toggle
                                            disabled={loading}
                                            variant="outline-secondary">
                                            {billingState ? billingState.name : 'State...'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {statesList.map((val, idx) => (
                                                <Dropdown.Item
                                                    eventKey={val.abbr}
                                                    key={idx}
                                                    onClick={() => {
                                                        setBillingState(val)
                                                        let newInputValue = Object.assign(
                                                            {},
                                                            inputValue
                                                        )
                                                        newInputValue.billing_state.value = val.abbr
                                                        setInputValue(newInputValue)
                                                    }}
                                                    active={
                                                        inputValue.billing_state.value === val.abbr
                                                    }>
                                                    {val.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                                <FormError message={errorFields['billingState']} />
                            </EnrollmentFormCol>
                            <EnrollmentFormCol>
                                <Form.Group>
                                    <Form.Label>{t('Zipcode')} *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="billing_zipcode"
                                        placeholder=""
                                        maxLength={5}
                                        value={inputValue.billing_zipcode.value}
                                        onChange={handleChangeInput}
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <FormError message={errorFields['billing_zipcode']} />
                            </EnrollmentFormCol>
                        </EnrollmentFormRow>
                    </div>
                )}
                {getDynamicFields('ext_input')}
                <CreditCheckSection>
                    <h5>{t('Utility Credit Check')}</h5>
                    <p>
                        {t(
                            'Your information is securely transmitted over an encrypted connection to the selected Retail Energy Provider. Texas Retail Energy Providers require personal identification information to review and process your application. Utility credit check does not affect your credit score. EIQshopping.com will not store, use, or sell any of the personal information we collect.'
                        )}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="flex-fill">
                            {getDynamicFields('credit_check')}
                        </div>
                    </div>
                </CreditCheckSection>
                <EnrollmentFormRow>
                    <EnrollmentFormCol full>
                        <CheckboxItem
                            label={t(
                                'I hereby authorize EIQshopping to run a credit check and perform all necessary tasks to establish electricity service. I accept the terms and conditions of the EIQshopping.'
                            )}
                            value={`${isAgree}`}
                            checked={isAgree}
                            handleClick={() => {
                                setIsAgree(!isAgree)
                            }}
                            onlyread={false}
                            disabled={loading}
                            id='is-agree-tos'
                        />
                        <FormError message={errorFields['isAgree']} />
                    </EnrollmentFormCol>
                </EnrollmentFormRow>
                <EnrollmentStepFooter>
                    <Button variant="secondary" disabled={loading} onClick={() => handleBack()}>
                        {t('Back')}
                    </Button>
                    <Button type="button" onClick={() => handleSave()} data-test-id="cta">
                        <span>{t('Review')}</span>
                        {loading && (
                            <Spinner
                                animation="border"
                                variant="light"
                                size="sm"
                                className="ms-2"
                            />
                        )}
                    </Button>
                </EnrollmentStepFooter>
            </Form>
            <Modal size="xl" show={isEzSwitch} onHide={() => { setIsEzSwitch(false) }} centered dialogClassName="custom-modal">

                <Modal.Header closeButton>
                    <Modal.Title>sync EzSwitch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ margin: '0 20px' }}>
                        We need to access your Retail Energy Provider(REP) account to automatically sync and register the information for your selected plan - no manual input needed
                    </p>
                    <Form>

                        <EnrollmentFormRow style={{ margin: 0 }}>
                            <EnrollmentFormCol style={{ margin: 0, flex: '0 0 100%' }}>
                                <Form.Group style={{ margin: "10px 0" }}>
                                    <Form.Label>{t('Retail Energy Provider(REP)')}</Form.Label>
                                    <Dropdown className="plans-filter-dd">
                                        <Dropdown.Toggle disabled={loading} variant="outline-secondary">
                                            {inputValueEzSwitch.provider.value
                                                ? inputValueEzSwitch.provider.value.utility_name
                                                : `${t('Please Select')}...`}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {providerList.map((val:any, idx) => (
                                                <Dropdown.Item
                                                    eventKey={val.utility_code}
                                                    key={val.utility_code}
                                                    onClick={() => {
                                                        let newInputValue = Object.assign({}, inputValueEzSwitch)
                                                        newInputValue.provider.value = val
                                                        setInputValueEzSwitch(newInputValue)
                                                    }}
                                                    active={
                                                        inputValueEzSwitch.provider.value.utility_code ===
                                                        val.utility_code
                                                    }>
                                                    {val.utility_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                </Form.Group>
                                <FormError message={errorFieldsEZ['provider']} />
                            </EnrollmentFormCol>
                        </EnrollmentFormRow>
                        <EnrollmentFormRow style={{ margin: 0 }}>
                            <EnrollmentFormCol style={{ margin: 0, flex: '0 0 100%' }}>
                                <Form.Group style={{ margin: "10px 0" }}>
                                    <Form.Label>{t('Username')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        maxLength={50}
                                        value={inputValueEzSwitch.username.value}
                                        onChange={handleChangeInputEvSwitch}
                                        disabled={loading}
                                        placeholder="Please enter your account..."
                                    />
                                </Form.Group>
                                <FormError message={errorFieldsEZ['username']} />
                            </EnrollmentFormCol>
                        </EnrollmentFormRow>
                        <EnrollmentFormRow style={{ margin: 0 }}>
                            <EnrollmentFormCol style={{ margin: 0, flex: '0 0 100%' }}>
                                <Form.Group style={{ margin: "10px 0" }}>
                                    <Form.Label>{t('Password')}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        maxLength={50}
                                        value={inputValueEzSwitch.password.value}
                                        onChange={handleChangeInputEvSwitch}
                                        placeholder="Please enter your password..."
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <FormError message={errorFieldsEZ['password']} />
                            </EnrollmentFormCol>
                        </EnrollmentFormRow>
                        <EnrollmentFormCol>
                            <CheckboxItem
                                label={t('I agree to allow EIQshopping to sync any basic MyAccount Information')}
                                value={inputValueEzSwitch.ischeck.value}
                                checked={inputValueEzSwitch.ischeck.value}
                                handleClick={() => {
                                    let newinputValueEzSwitch = Object.assign({}, inputValueEzSwitch)
                                    newinputValueEzSwitch.ischeck.value = !newinputValueEzSwitch.ischeck.value
                                    setInputValueEzSwitch(newinputValueEzSwitch)
                                }}
                                onlyread={false}
                                disabled={loading}
                            />
                            <FormError message={errorFieldsEZ['ischeck']} />
                        </EnrollmentFormCol>
                    </Form>
                    <p style={{ margin: '0 20px', fontSize: 12, color: '#666' }}>Note: EIQshopping will only access your information during the synchronization process and will not retain any of your personal information.</p>
                    <div style={{ textAlign: 'center', width: '100%', marginTop: 10 }}><Button onClick={EzSwitchSubmit}>CONTINUE
                        {loading && (
                            <Spinner
                                animation="border"
                                variant="light"
                                size="sm"
                                className="ms-2"
                            />
                        )}</Button></div>
                </Modal.Body>
            </Modal>
        </EnrollmentStepWrapper>
    )
}
