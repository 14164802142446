import styled from "styled-components";

interface CardProps {
  color?: string;
  sm?: boolean;
  triangle?: boolean;
  border?: boolean;
  bColor?: string;
  md?: boolean;
  variant?: string;
}

export const Card = styled.div<CardProps>`
  padding: 18px;
  border-radius: 22.5px;
  position: relative;
  width: 100%;
  background-color: ${(props) => (props.color ? props.color : "#fff")};
  border: ${(props) =>
    props.border
      ? `1.5px solid ${props.bColor ? props.bColor : "#eef0f2"}`
      : "0 none"};
  box-shadow: 0px 0px 20px #cfddff;

  ${(props) =>
    props.md &&
    `
        display:flex;
        align-items:center;
        justify-content:center;
        gap:10px;
        flex-direction:column;
    `}
`;

interface ButtonProps {
  bkcolor?: string;
  color?: string;
  bdcolor?: string;
  sm?: boolean;
  hovercolor?: string;
  hoverbk?: string;
}

export const MyButton = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : "#fff")};
  background: ${(props) => (props.bkcolor ? props.bkcolor : "#cfddff")};
  border: ${(props) =>
    props.bdcolor ? `1.5px solid ${props.bdcolor}` : "0 none"};
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity 0.3s;
  padding: ${(props) => (props.sm ? `10px 15px` : "12px 27px")};
  transition: background 0.3s;
  :hover {
    background: #6b8aff;
    transition: background 0.3s;
  }
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

interface PopCardProps {
  sm?: boolean;
  same?: boolean;
  green?: boolean;
}

export const PopCard = styled.div<PopCardProps>`
  padding: 40px;
  margin: 9px 0;
  background-color: #fff;
  border: 9px solid;
  border-color: ${({ green }) => (green ? "#F0FCF3" : "#ecf1ff")};
  width: 100%;
  height: 100%;
  position: relative;

  min-height: ${(props) => (props.same ? "200px" : "auto")};
  display: ${(props) => (props.same ? "flex" : "block")};
  align-items: ${(props) => (props.same ? "center" : "unset")};

  border-radius: ${(props) => (props.sm ? "6px" : "0")};

  ::before,
  ::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
  }

  ::before {
    border-right: 48px solid transparent;
    border-top: ${(props) => (props.sm ? "36px solid" : "48px solid")};
    border-top-color: ${({ green }) => (green ? "#F0FCF3" : "#ecf1ff")};
    bottom: ${(props) => (props.sm ? "-36px" : "-48px")};
    left: -9px;
  }

  ::after {
    border-right: 28px solid transparent;
    border-top: ${(props) =>
      props.sm ? "21px solid #fff" : "28px solid #fff"};
    bottom: ${(props) => (props.sm ? "-21px" : "-28px")};
    left: 0px;
  }
`;

export const HomeAsideBox = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  .img-aside {
    width: 50%;
  }

  @media (max-width: 990px) {
    flex-direction: column;
    .img-aside {
      width: 100%;
    }
  }
`;

export const TitleLine = styled.div`
  font-size: 32px;
  font-weight: 600;
  text-align: center;

  @media (max-width: 990px) {
    font-size: 24px;
  }
`;

export const CardTitleLine = styled(TitleLine)`
  font-size: 24px;
  font-weight: 600;
  text-align: start;
  @media (max-width: 990px) {
    font-size: 18px;
  }
`;

export const CardIntro = styled(TitleLine)`
  font-size: 16px;
  text-align: start;
  @media (max-width: 990px) {
    font-size: 14px;
  }
`;

export const MapHeroWrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: 300px;
  margin-top: 20px;

  @media (max-width: 600px) {
    height: 400px;
    margin-top: 0;
  }
`;

export const MapHeroBlock = styled.div`
  max-width: 1200px;
  overflow: hidden;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  border-radius: 16px;

  @media (max-width: 600px) {
    height: auto !important;
    border-radius: 0;
  }
`;

export const MapHeroContainer = styled.div`
  height: 120%;
  width: 120%;
  position: relative;
  top: -30%;

  @media (max-width: 600px) {
    height: 200%;
    width: 200%;
    top: 50px;
    left: -50%;
  }
`;

export const MapHeroMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
`;

export const MapHeroContent = styled.div`
  position: relative;
  z-index: 1;
  height: 0;
`;

export const MapHeroContentInner = styled.div`
  background-color: #fff;
  padding: 20px;
  max-width: 40%;
  position: relative;
  top: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.04);

  @media (max-width: 900px) {
    max-width: 50%;
  }

  @media (max-width: 600px) {
    max-width: 100%;
    top: 20px;
  }
`;

export const MapHeroContentSolarInner = styled.div`
  background-color: #fff;
  padding: 15px 60px 15px 20px;
  max-width: 40%;
  position: relative;
  top: 40px;
  border-radius: 50px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  display: flex;
  margin-top: 10px;

  h5 {
    font-size: 15px;
  }

  img {
    position: absolute;
    object-fit: contain;
    height: 100%;
    display: block;
    right: 0;
    top: 0;
    bottom: 0;
  }

  @media (max-width: 900px) {
    max-width: 50%;
  }

  @media (max-width: 600px) {
    max-width: 100%;
    margin-top: 0;
    top: 160px;

    h5 {
      font-size: 13px;
    }
  }
`;

export const HouseInfoChip = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #f2f2f2;
  padding: 4px 12px;
  font-size: 14px;
  color: #444;
  border-radius: 24px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const SolarIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #82be46;
  padding: 4px 12px;
  font-size: 14px;
  color: #fff;
  border-radius: 24px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
`;

export const GeoChartLegend = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  span {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    font-size: 13px;
    color: #666;
    line-height: 1.3;

    i {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      flex-shrink: 0;
    }
  }

  @media (max-width: 600px) {
    flex-direction: row;
    padding-top: 20px;

    span {
      flex-direction: row-reverse;
    }
  }
`;

export const SolarMapBlock = styled.div`
  margin: 0 auto;
  overflow: hidden;
  border-radius: 16px;

  @media (max-width: 600px) {
    border-radius: 0;
  }
`;

export const SolarMapContainer = styled.div`
  height: 200%;
  width: 200%;
  position: relative;
  top: -50%;
  left: -30%;
  min-height: 300px;

  .address-page-google-map {
    min-height: 400px;
  }

  @media (max-width: 600px) {
    width: 250%;
    top: -40%;
    left: -80%;
  }
`;
