import { Box, Container, Divider, Typography } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../components/styles/MyAccordion";
import Header from '../components/header'

const FAQs = () => (
  <>
    <Header />
    <Container>
      <Box py={5}>
        <Typography variant="h2" fontWeight="700" mb={4}>
          FAQs
        </Typography>

        <Box my={4}>
          <Typography variant="h5" color="#000" fontWeight="700" mb={3}>
            Energy Choice Basics
          </Typography>

          <Accordion>
            <AccordionSummary>
              <Typography>What is Energy Choice?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Energy choice, also known as Energy Deregulation, allows open
                competition in energy markets, giving consumers the ability the
                power to choose their energy supplier.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>What is an Energy Supplier?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                An energy supplier, which may also be called an energy provider,
                a Competitive Retail Electric Service provider (CRES), or retail
                energy provider (REP) depending on the state you live in, is a
                company who purchases electricity from the wholesale market and
                sells it directly to consumers such as yourself at retail prices
                that they control.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                Does everyone have a choice in who provides their energy
                services?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Not everyone has a choice. It depends on the state and area in
                which they live.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                How do I know what is the best energy plan for me?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Comparing energy plans can be very difficult. Which is why
                ElQshopping does the work for you. Just enter your
                address and we do the work for you. We collect information on
                energy offers available in your area, analyze your home and
                personal usage profile, and recommend the best energy plan for
                you. If you want to see more options, that is okay too; we
                provide you with an apples-to-apples comparison, showing you how
                much you will spend based on your usage information, so that you
                can choose your electricity plan and provider to best fit your
                needs. We make it simple and easy for you to find the best plan
                for you.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                What is the difference between a fixed rate plan and a variable
                rate plan?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                With a fixed-rate plan, your energy price and terms are locked
                in for the life of the contract. This means that if the
                wholesale price of electricity goes up due to a particularly
                cold winter, a hot summer, or an unforeseen event, you will be
                protected from these price spikes and market fluctuations. Fixed
                rate plans typically last anywhere between 3 months to upwards
                of 3 or more years. While the rate is protected for the length
                of the contract, fixed-rate plans also typically require you to
                do a credit check, may require you to pay a deposit, and often
                come with an early termination fee.
              </Typography>
              <Typography>
                With a variable-rate plan, your rate may change month-to-month
                as the market prices fluctuate. While this puts you at risk for
                higher pricing if market prices go up, you also can take
                advantage of lower pricing if energy prices go down. Be aware,
                though, these plans can fluctuate widely and you may be paying
                the highest rates when you also have the highest usage. While
                these plans come with the benefits of no early termination fees,
                being able to switch providers at any time, and the ability to
                sometimes enjoy lower pricing, they also come with greater risk
                than a fixed plan.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>What is an Indexed plan?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Indexed Electricity plans, simply means that your electricity
                price is tied to another variable. Some energy providers tie the
                rate to a publicly available index, such as the NYMEX natural
                gas price; others may tie the rate you pay to things, such as
                time of day, day of the week, and so forth. For these plans, the
                underlying variable that is used to calculate what you pay must
                be fully disclosed in your contract. Be advised, though, not all
                markets have indexed plans. While these plans carry many
                benefits, it can be difficult to calculate what you should be
                charged and often require a lot of monitoring by you to ensure
                that you receive the highest savings.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>What is a Time-of-Use Plan?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A Time-of-Use (TOU) plan is simply an indexed plan tied to the
                time you use your electricity. For these plans, you must be
                aware of how much electricity you use and when. These work best
                when you are able and willing to shift a large portion of your
                electricity usage to when the rate is cheapest. It should also
                be noted, however, that the rate for non-discounted hours is
                often very high.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                What is a utility and its role in the energy market?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Utilities maintain infrastructure and deliver energy. They own
                and manage the electric wires, poles, and hardware that delivers
                electricity to your home. The Utility, which may also be called
                a Transmission and Distribution Service Provider (TDSP), a
                Transmission & Distribution Utility (TDU) or Electric
                Distribution Utility (EDU) depending on the state you live in,
                is the company that is responsible for getting the electricity
                from the generators to your home. They are responsible for
                maintaining the reliability of the grid and, if the power goes
                out in your area, they are the ones that are responsible for
                getting your power restored. Utilities are specific to an area
                and cannot be chosen by consumers. So, no matter what
                electricity plan you choose, or who is your energy supplier,
                your Utility will remain the same.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                What if my provider goes out of business? Will my service stop?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Not to worry, your service will not stop. Again, depending on
                which state and area your property is located in, you&apos;ll be
                automatically switched to a different provider or directly to
                your utility. There should be absolutely no interruptions in
                your electricity service.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Divider />

        <Box my={4}>
          <Typography variant="h5" color="#000" fontWeight="700" mb={3}>
            Renewable Energy
          </Typography>

          <Accordion>
            <AccordionSummary>
              <Typography>What is Renewable Energy?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Renewable energy is energy produced from renewable sources like
                the sun and wind. These renewable sources naturally replenish
                themselves and do not run out. Oftentimes, windpower, solar
                power, hydro power and other renewable energy shows up on your
                electricity bill as Renewable Content.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>What is Renewable Content?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Renewable Content is the percentage of electricity generated
                from renewable resources. Plans that offer a high percentage of
                green energy are often called Green Electricity plans, Renewable
                Content Energy plans or Green Energy plans. While the
                electricity to your home is exactly the same regardless of the
                plan you select, your electricity provider will be required to
                purchase Renewable Energy Certificates based on Renewable
                Content percentage for your plan.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                What is a Renewable Energy Certificate (REC)?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When a generator generates electricity, it goes directly to the
                grid in real time. This power is delivered to your home on
                demand. However, to encourage the use of renewable resources,
                the energy industry created something called Renewable Energy
                Certificates (RECs). The way it works is that for every 1 MWh
                (1000 kWh) of power generated by a generator that uses renewable
                energy, one Renewable Energy Certificate (REC) is also
                “created”. These RECs are then sold separately from the power
                they generate to energy companies. In this way, and energy
                company can sell green energy and promote green energy plans and
                offers.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>How do I purchase green energy?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Many Retail Electric Providers offer green energy plans. Use
                ElQshopping and enter your ZIP code to explore renewable
                energy options like wind, solar, and other plans using Renewable
                Energy Certificates (RECs) for carbon offset.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Divider />

        <Box my={4}>
          <Typography variant="h5" color="#000" fontWeight="700" mb={3}>
            Shopping for Electricity
          </Typography>

          <Accordion>
            <AccordionSummary>
              <Typography>
                Can I switch electricity providers at any time?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                If you are on a fixed-rate electricity plan with a contract term
                that has not completed, you may be charged an early termination
                fee. Before switching, check your contract expiration date
                (which should be located on your electricity bill) and the terms
                of your contract related to Early Termination Fees. With that
                said, if your contract has ended, or you are on a variable-rate
                plan, you are free to switch at any time without penalty.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>How do I find the best electricity rates?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Use ElQshopping to find the best plans to fit your
                needs. Just enter your address, and our AI technology will
                compare rates and plans from different providers in your area.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>How often do rates change?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                This will depend on your state and area regulations. In a state
                such as Texas, a Retail Energy Provider can change as often as
                they like. Therefore, you may see energy prices change daily, or
                even throughout the day. However, in other states, Energy
                Suppliers might not be able to change rates that frequently. In
                some states, pricing can only change once a month, at the
                beginning of the month.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                How much money will I save by switching to a new plan?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The amount of money you save by switching to a new electricity
                plan or provider will certainly depend on your usage and the
                offers available in your area at the time you switch. However,
                the good news is that we provide you with an apples-to-apples
                comparison of the market offerings, so you can pick the best
                plan for your home. (Be advised, if you are currently in the
                middle of a fixed-rate plan and you have not completed the term
                of your contract, you may be charged an early termination fee.)
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>Will I have to pay a deposit?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                This will depend on the type of energy plan you choose and the
                energy provider. If you have any concerns about paying a
                deposit, we encourage you to look at the terms and conditions,
                also sometimes referred to as Terms of Service (TOS), of the
                plan. If you still have questions, please contact the provider
                directly.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                How long does it take to switch my home’s electric service?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The length of time to switch service varies depending on the
                state you your home is located in and your provider. While in
                some states you may be able to switch in one to two days, in
                other states it may not happen until your next billing cycle.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                Will I lose power during the transition to a new energy
                supplier?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No, your electricity will not be interrupted and your lights
                will not go out because you are switching energy suppliers. Your
                local utility will be notified by your new energy supplier that
                you are switching, and the Utility will coordinate the switch
                with your current and your new providers.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                Do I need to notify my current energy provider to let them know
                that I am switching?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No. As a matter of fact, we recommend that you don’t. Your local
                utility will be notified by your new energy provider that you
                are switching, and the Utility will coordinate the switch. If
                you notify your current provider and provide them with a date
                prior to the date you enrolled with the new supplier, you could
                run the risk of losing power.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                Will anything change if I switch suppliers?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The main change will be the price you pay for electricity and
                who gets paid for supplying your home with that electricity.
                Whil, in some states such as Texas, you will receive your
                electric bill from your new retail electricity provider, in most
                states, you will continue to receive your electric bill from
                your local utility. However, no matter where you live, your
                local utility company will stay the same. If there is an outage
                in your area or any issues with the power line, you will
                continue to call your local utility.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                Will my choice of an electric company affect the reliability of
                my electricity service?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The electricity provider you choose will not impact the delivery
                and reliability of electricity. Regardless of who your energy
                provider is, your local utility will be responsible for the
                reliability of your electricity service.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                Can I change my mind after I switch providers?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                There are different rules in different states. For example, in
                Texas if you are switching retail energy providers, you have up
                to 3 business days to rescind the offer without penalty. If you
                change your mind, call the new energy supplier as soon as
                possible to discuss your options with them.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Divider />

        <Box my={4}>
          <Typography variant="h5" color="#000" fontWeight="700" mb={3}>
            Energy Billing
          </Typography>

          <Accordion>
            <AccordionSummary>
              <Typography>Who do I pay my bill to?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Depending on the state you live in, you will either pay your
                local utility company directly, or you will pay your energy
                supplier. To confirm who to pay, please look at your bill, where
                you will find payment remittance directions. Please do not send
                your payment to ElQshopping.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>Why do I pay a fee to my utility company?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                While you have the power to choose your plan in deregulated
                areas, you should be aware that there are fees for the
                transmission and distribution of electricity to your home that
                will be charged by the utility company regardless of which plan
                you choose. While some energy providers will “bundle” these
                rates into the price they quote, more often than not, you will
                see these rates as a line item on your bill. These fees are used
                to help manage the electric wires, poles, and hardware that
                delivers electricity to your home, and help to fund the services
                provided by the utility company.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                Why are there different prices for different usage levels?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                If you are asking this question, you probably live in Texas. In
                Texas, the Public Utility of Texas (PUCT), who regulates the
                market, requires Retail Electricity Providers display the rates
                on an Electricity Facts Label (EFL) at usage levels of 500,
                1000, and 2000 kWh. You will notice that the prices may vary at
                the different usage levels. This is due to additional fees such
                as Base Fees, Minimum Usage Charges, and Usage fees, rates, or
                credits. Your usage will vary month-to-month, so these are just
                for display purposes and these are solely example prices. With
                that said, when you input your address at ElQshopping,
                we will take your home’s usage profile and display for you a
                customized rate tailored to your home and usage regardless of
                what state or area you live in.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>Why is my bill so high?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                How much you pay for your electricity is a function of how much
                energy you use and the energy rate and fees specific to your
                electricity plan.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                I am paying a lot of money for my electricity bill, is the
                energy provider keeping all that money?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No, certainly not. Depending on your utility, typically about
                half of your bil, though sometimes more or less depending on
                where you live,l is actually what your utility is charging for
                the transmission and distribution of the electricity to your
                home. On top of that, the energy provider has to purchase the
                electricity that you used from the wholesale market and has to
                pay the generators for that electricity. Whatever remains after
                your utility is paid and the energy supplier pays for the
                wholesale electricity, the energy supplier will keep to pay
                their own bills. In other words, while most people think that
                the energy supplier makes large margins because the customer is
                paying high bills, in reality, they are not. A retail energy
                provider typically makes enough to stay in business, but are not
                making nearly as much as you might think.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Divider />
        <Box my={4}>
          <Typography variant="h5" color="#000" fontWeight="700" mb={3}>
            Who to Contact and When
          </Typography>

          <Accordion>
            <AccordionSummary>
              <Typography>
                Who should I contact in case of a power outage?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Contact your local utility company. Your utility company’s name
                and their toll-free number should be located on your electricity
                bill.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                What happens if I have a billing issue or a question about my
                electricity bill?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                This depends on where you live. In Texas, you will contact your
                retail energy provider. However, in other states, you may need
                to contact your utility. To know who you should contact for
                billing and customer support issues, please look at your bill.
                Your bill should provide you with billing and outage customer
                assistance information, including who to call and their contact
                information.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
                When do I need to contact ElQshopping?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                If you have questions about ElQshopping’s website,
                please feel free to reach out to us at: 1-855-EIQ-DIGI
                (1-855-347-3444). We simplify the process and provide you with a
                simple and friendly way to view electricity offers based on your
                usage. However, we do not create or maintain the plans or
                prices, and we cannot assist you with issues once you have
                enrolled in a plan. For questions about plans and pricing, or
                for issues with your bill, please contact your energy supplier
                or utility as appropriate in your area.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Divider />

        {/* <Box my={4}>
          <Typography variant="h5" color="#000" fontWeight="700" mb={3}>
            About ElQshopping
          </Typography>

          <Accordion>
            <AccordionSummary>
              <Typography>Who is EIQshopping?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              EIQshopping specializes in helping you find the ideal energy plan tailored to your specific needs. 
              Leveraging the deep expertise of professionals from the energy and technology sectors, we simplify 
              the process of selecting
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
              How is EIQshopping different? Why choose us for your energy supplier and electricity plan?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              EIQshopping sets itself apart from other energy brokers by offering a more comprehensive and accurate selection process. Unlike others that rely on general usage estimates like 500/1000/2000 kWh, we delve into your home’s unique energy consumption patterns. Our advanced AI analyzes thousands of plans daily, forecasting costs with precision by considering factors like tiered rates, seasonal changes, and other hidden charges. This means you get a tailored plan recommendation that’s truly based on your actual needs, helping you avoid unpleasant surprises on your bill—all without any cost to you.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary>
              <Typography>
              I entered my address, but I do not see energy offers available in my area, why not?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              EIQshopping displays energy plans only in deregulated markets, where consumers have the freedom to choose their energy provider. Although we are rapidly expanding, there are still areas where our service isn’t available. If you’re in a deregulated market but don’t see any offers yet, it’s likely that we haven’t reached your area just yet. Check back soon as we continue to grow our coverage. 
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box> */}
      </Box>
    </Container>
  </>
);

export default FAQs;
